@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// font-family: 'Poppins', sans-serif;
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// microsite color
$textWhite: #fff;
$background-white: #fff;
$textblack: #000;
$microsite: #6378ff;
$micrositeBtn: #6378ff;
$lightBg: #f6f7fe;
$footerBG: #f6f7fe;
$popins-stack: 'Poppins', sans-serif;
$paragraph_color: #333333;
$light_text_color: #888888;
$footer_text_color: #616161;
$green_complete: #68d973;
$deep_Blue: #5a6de8;
$btn_header_btn: #d96367;
$text_footer: #49454f;
$money_text: #939094;
$label_color: #F7F8FF;
$text_color: #1c1b1f;
$danger: #B3261E;
$activation_text: #49454f;

$subscribe_green_check: #3bba59;
$text_para_color: #49454f;
$value_summary_border: #cfd5ff;
$value_summary_text_color: #49454f;
$appli_coupon_bg: #c6f1d0;
$appli_coupon_heading_color: #3bba59;
$save_box_bg: #fdc44a;
$modal_border: #79747e;
$apply_active: #c6f1d0;
$apply_color: #3bba59;
$discount_green: #3bba59;
$class_point_pro_box: #fafbff;

@mixin microsite_btn() {
    width: auto;
    padding: 8px 29px !important;
    text-transform: initial;
    font-size: 16px;
    height: auto;
    min-width: auto;
    line-height: 1.75 !important;
    min-height: auto;
    border-radius: 8px;
    text-decoration: none !important;
    font-weight: 500;
    box-shadow: none !important;
    font-family: $popins-stack;
}

@mixin light_btn() {
    width: auto;
    padding: 6px 66px !important;
    text-transform: initial;
    font-size: 14px;
    height: auto;
    min-width: auto;
    line-height: 1.71 !important;
    min-height: auto;
    border-radius: 4px;
    text-decoration: none !important;
    font-weight: 500;
    box-shadow: none !important;
    font-family: $popins-stack;
    background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #ffffff;
    color: $microsite;
    border: 2px solid #eef0ff;
}

@mixin purchase_btn() {
    width: auto;
    padding: 12px 45px !important;
    text-transform: capitalize;
    font-size: 14px;
    height: auto;
    min-width: auto;
    line-height: 1.75 !important;
    min-height: auto;
    border-radius: 8px;
    text-decoration: none !important;
    font-weight: 500;
    box-shadow: none !important;
    background: $micrositeBtn;
}

@mixin microsite_header_btn() {
    width: auto;
    padding: 6px 24px !important;
    text-transform: initial;
    font-size: 14px;
    height: auto;
    min-width: auto;
    line-height: 20px !important;
    min-height: auto;
    border-radius: 8px;
    text-decoration: none !important;
    font-weight: 500;
    box-shadow: none !important;
}

@mixin subcribe_btn() {
    width: auto;
    max-width: 185px;
    padding: 12px 30px !important;
    text-transform: initial;
    font-size: 14px;
    height: auto;
    font-family: $popins-stack;
    min-width: auto;
    line-height: 20px !important;
    min-height: auto;
    border-radius: 4px;
    text-decoration: none !important;
    font-weight: 500;
    box-shadow: none !important;
}

@mixin payment_subcribe_btn() {
    width: auto;
    max-width: 185px;
    padding: 12px 40px !important;
    text-transform: initial;
    font-size: 14px;
    height: auto;
    font-family: $popins-stack;
    min-width: auto;
    line-height: 20px !important;
    min-height: auto;
    border-radius: 0px;
    text-decoration: none !important;
    font-weight: 400;
    box-shadow: none !important;
    background: #385da6;
}

@mixin header_h1() {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
}

@mixin header_h2() {
    font-size: 35px;
    line-height: 42px;
}

@mixin header_h3() {
    font-size: 30px;
    line-height: 38px;
}

@mixin header_h4() {
    font-size: 26px;
    line-height: 34px;
}

@mixin header_h5() {
    font-size: 22px;
    line-height: 30px;
}

@mixin header_h6() {
    font-size: 20px;
    line-height: 38px;
}

@mixin header_h7() {
    font-size: 18px;
    line-height: 26px;
}

@mixin header_h8() {
    font-size: 16px;
    line-height: 24px;
}

@mixin parag_a() {
    font-size: 20px;
    line-height: 38px;
}

@mixin parag_p() {
    font-size: 20px;
    line-height: 30px;
}

@mixin parag_p3() {
    font-size: 10px;
    line-height: 18px;
}

@mixin footer_head() {
    font-size: 14px;
    line-height: 20px;
}

@mixin footer_link() {
    font-size: 12px;
    line-height: 18px;
}

// universal style
* {
    box-sizing: border-box;
}

p {
    padding: 0px;
    margin: 0px;
}

a {
    padding: 0px;
    margin: 0px;
    outline: none;
}

ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

a:hover {
    outline: none !important;
    text-decoration: underline !important;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

a:active {
    outline: none !important;
    text-decoration: none;
}

a {
    text-decoration: none !important;
}

label {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

ul li {
    padding: 0px;
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0px;
    margin: 0px;
}

/* width */
::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    min-height: 5px !important;
    max-height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #d6d6d6;
    height: 5px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(138, 138, 138);
    height: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(153, 153, 153);
    height: 5px !important;
}

// all
.container {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
}

.MuiModal-root .MuiBox-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;

    max-width: 400px;
    background-color: rgb(255, 255, 255);
    border: none;
    box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
    padding: 32px;
}