@use '../Style.scss' as *;


.download_header {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 6px 2px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 11;

    .header_logo img {
        width: 28px;

    }

    .container {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
    }

    .box_align {
        align-items: center;
        justify-content: space-between;
        display: flex;
    }

    .download_header .MuiToolbar-gutters {
        padding-left: 0px;
        padding-right: 0px;
        min-height: auto;
    }

    .MuiToolbar-root {
        min-height: auto;
        padding-left: 22px;
        padding-right: 22px;
    }

    .header_logo {
        padding: 16px 0;
        display: flex;
        align-items: center;
        gap: 20px;

        p {
            font-size: 20px;
            font-family: $popins-stack;
            color: $text_color;
            font-weight: 500;
        }
    }

    .MuiAppBar-colorPrimary {
        background-color: $background-white;
        box-shadow: none;
    }
}


// download_body
.download_body {
    width: 100%;

    .container {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
    }

    .download_inner {
        width: 100%;
        padding-inline: 22px;
        padding-block-start: 32px;

        .download_content {
            display: flex;
            align-items: center;
            gap: 10px;

            p {
                font-family: $popins-stack;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: $text_color;

                &.red {
                    color: $danger;
                }
            }
        }

        span {
            font-family: $popins-stack;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: $activation_text;
            padding-block-start: 8px;
            display: block;
        }
    }
}